import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13')
];

export const server_loads = [0,4];

export const dictionary = {
		"/(site)": [~5,[2],[3]],
		"/(site)/genre/[slug]": [~7,[2],[3]],
		"/(site)/kalender": [~8,[2],[3]],
		"/(site)/kalender/[slug]": [~9,[2],[3]],
		"/(site)/kalender/[slug]/pressebilleder": [~10,[2],[3]],
		"/message/[order]/[showing]": [11,[4]],
		"/message/[order]/[showing]/tickets": [12,[4]],
		"/slideshow": [~13],
		"/(site)/[...path]": [~6,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';