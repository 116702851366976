import { Bugs } from '$lib/BugsService';
import type { HandleClientError } from '@sveltejs/kit';

/**
 * Handle hooks
 */
// const i18nHandle: Handle = async ({ event, resolve }) => {
// 	event.locals.lang = 'da';
// 	return resolve(event);
// };

// export const handle = sequence(i18nHandle /*...otherHandleHooks */);

/**
 * Error hooks
 */
export const handleError: HandleClientError = (({ error, event }) => {
	const errorId = crypto.randomUUID();

	let message = 'Unexpected Client Error';

	if (error instanceof Error) {
		message = error.message;
	}

	Bugs.reportError(new Error(message), { error, event, errorId });

	console.error(error);
	return {
		message: 'An unexpected error occurred.',
		errorId
	};
}) satisfies HandleClientError;
